import React, { PureComponent } from 'react';
import './ErrorPage.css';
import Typography from '@material-ui/core/Typography';

class ErrorPage extends PureComponent {
  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center h-100vh">
          <div className="Login col col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <Typography variant="display3" gutterBottom>
              Oh, No...
            </Typography>
            <h1>Technischer Fehler</h1>
            <p>
              Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es
              zu einem späteren Zeitpunkt erneut.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
