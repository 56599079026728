import {MenuItem} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {Form, Formik} from 'formik';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import * as Yup from 'yup';
import * as CONSTANTS from '../constants';
import DropContainer from '../generic_components/DropContainer';
import RegistrationConsentPopUp from './RegistrationConsentPopUp';

class UploadCv extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileErrorMessage: '',
      fileLoading: false,
      commonErrorMessage: '',
      showRegistrationConsentPopUp: false
    };
  }

  handleCloseRegistrationConsentPopUp = () => {
    this.setState({ showRegistrationConsentPopUp: false });
  };

  handleOpenRegistrationConsentPopUp = () => {
    if (this.validateInputFields()) {
      this.setState({ showRegistrationConsentPopUp: true });
    }
  };

  formatDateToDELocale(date) {
    if (!date) return '';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('de-DE', options);
  }

  formatStringToDELocale(dateString) {
    let formattedDate = '';
    if (!dateString || dateString.length === 0) formattedDate = '';
    else formattedDate = this.formatDateToDELocale(new Date(dateString));
    return formattedDate;
  }

  renderPreviouslyUploadedContent() {
    return this.props.uploadedDocuments.length === 0 ? (
      <div className="row ">
        <div className="col-12">Bisher wurden keine Dokumente hochgeladen</div>
      </div>
    ) : (
      <div>
        <div className="row ">
          <div className="col-8">
            <strong>Name:</strong>
          </div>
          <div className="col-4">
            <strong>Datum:</strong>
          </div>
        </div>
        {this.props.uploadedDocuments.map((uploadedDocument) => (
          <div key={uploadedDocument.uploadDate} className="row ">
            <div className="col-8">{uploadedDocument.fileName}</div>
            <div className="col-4 ">{this.formatStringToDELocale(uploadedDocument.uploadDate)}</div>
          </div>
        ))}
      </div>
    );
  }

  onFileDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0)
      this.setState({
        fileName: acceptedFiles[0].name,
        fileSize: acceptedFiles[0].size,
        acceptedFiles
      });
  };

  validateInputFields = () => {
    if (
      (!this.state.acceptedFiles || !this.state.acceptedFiles[0]) &&
      (!this.props.uploadedDocuments || !this.props.uploadedDocuments[0]) &&
      !this.props.xingURL &&
      !this.props.linkedInURL
    ) {
      this.setState({
        commonErrorMessage: 'Bitte geben Sie Ihr Xing/LinkedIn Profil an oder laden Sie Ihren Lebenslauf hoch.'
      });
      return false;
    }
    this.setState({
      commonErrorMessage: ''
    });
    return true;
  };

  finishAndHandleNext = (e) => {
    e.preventDefault();
    this.setState({ showRegistrationConsentPopUp: false });
    const url = CONSTANTS.uploadMatchingProfileCvURL;
    this.setState({
      fileErrorMessage: '',
      fileLoading: true
    });

    const request = new FormData();
    request.append('guestToken', this.props.token);
    request.append('brand', 'lienert');
    request.append(
      'candidateData',
      JSON.stringify({
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        emailAddress: this.props.emailAddress.toLowerCase(),
        salutation: this.props.salutation,
        street: this.props.street,
        postalCode: this.props.postalCode,
        city: this.props.city,
        phoneNumber: this.props.phoneNumber,
        location: this.props.location,
        dateOfBirth: this.props.dateOfBirth,
        xingURL: this.props.xingURL,
        linkedInURL: this.props.linkedInURL
      })
    );

    if (this.state.acceptedFiles && this.state.acceptedFiles[0]) {
      request.append(`file-${0}`, this.state.acceptedFiles[0]);
    }

    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      timeout: 30000,
      data: request
    })
      .done((responseBody) => {
        if (responseBody) {
          this.setState({
            fileLoading: false
          });
          this.props.handleNext();
        }
      })
      .fail((err) => {
        console.log('ERR', err);
        if (err.status === 413 || err.status === 0) {
          this.setState({
            fileErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            fileLoading: false
          });
        } else if (err.status === 415) {
          this.setState({
            fileErrorMessage: 'Nicht unterstütztes Format',
            fileLoading: false
          });
        } else
          this.setState({
            fileErrorMessage: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut',
            fileLoading: false
          });
      });
  };

  renderDropZone() {
    return (
      <div className="row">
        <div className="col-md-6 ">
          <div className="row mb-3">
            <div className="col-md-12">
              <Typography color="error" paragraph variant="body1">
                {this.state.fileErrorMessage}
              </Typography>
            </div>

            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <DropContainer
                  dropzoneErrorMessage={this.state.acceptedFiles && `Datei:${this.state.fileName} Größe: ${this.state.fileSize / 1000} KB`}
                  showLoadingIndicator={this.state.fileLoading}
                  onDrop={this.onFileDrop}
                  disableClick={false}
                  actionDescription="Lebenslauf (PDF-Format) hier ablegen"
                >
                  <div className="confirmation-dialog-title">
                    <i className="material-icons document-upload-icon pb-3">cloud_upload</i>
                  </div>
                </DropContainer>
              </div>
            </div>
            <div className="col-md-12">{this.renderPreviouslyUploadedContent()}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row mb-3">
            <div className="col-12">
              <TextField
                color="primary"
                label="Xing Profil"
                value={this.props.xingURL}
                placeholder="z.B.: https://www.xing.com/profile/Ihr_Name"
                onChange={(e) => {
                  this.props.liftUpStateToMatchingProfile({
                    xingURL: e.target.value
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <path d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z" />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                maxLength="100"
                minLength="2"
                fullWidth
              />
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-12">
              <TextField
                color="primary"
                label="LinkedIn Profil"
                placeholder="z.B.:https://www.linkedin.com/in/ihr-name-123456789/"
                value={this.props.linkedInURL}
                onChange={(e) => {
                  this.props.liftUpStateToMatchingProfile({
                    linkedInURL: e.target.value
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                maxLength="100"
                minLength="2"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  requiredFieldString = 'ist ein Pflichtfeld';

  validatenSchema = Yup.object().shape({
    location: Yup.string('Standort').required(`Standort ${this.requiredFieldString}`),
    salutation: Yup.string('Anrede').required(`Anrede ${this.requiredFieldString}`),
    firstName: Yup.string('Vorname').required(`Vorname ${this.requiredFieldString}`),
    lastName: Yup.string('Nachname').required(`Nachname ${this.requiredFieldString}`),
    emailAddress: Yup.string('E-Mail').email('E-Mail hat nicht das richtige Format').required(`E-Mail ${this.requiredFieldString}`),
    phoneNumber: Yup.number('Telefonnummer')
      .typeError('Telefonnummer hat nicht das richtige Format')
      .required(`Telefonnummer ${this.requiredFieldString}`),
    city: Yup.string('Ort').required(`Ort ${this.requiredFieldString}`),
    street: Yup.string('Straße').required(`Straße ${this.requiredFieldString}`),
    postalCode: Yup.number('Postleitzahl').required(`Postleitzahl ${this.requiredFieldString}`),
    dateOfBirth: Yup.date('Geburtsdatum')
      .typeError('Geburtsdatum hat nicht das richtige Format')
      .max(new Date(), 'Geburtsdatum darf nicht in der Zukunft liegen')
      .required(`Geburtsdatum ${this.requiredFieldString}`)
  });

  renderForm = () => (
    <Formik
      initialValues={this.props}
      validationSchema={this.validatenSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      validateOnMount
      onSubmit={() => {
        this.handleOpenRegistrationConsentPopUp();
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, touched }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row mb-2">
            <div className="col-md-12 col-lg-6 offset-lg-3">
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    select
                    fullWidth
                    required
                    id="location"
                    name="location"
                    label="Standort"
                    onBlur={handleBlur}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                    value={values.location}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        location: e.target.value
                      });
                    }}
                    native
                    inputProps={{
                      id: 'location-required'
                    }}
                  >
                    <MenuItem value="" />
                    <MenuItem value="Aarau">Aarau</MenuItem>
                    <MenuItem value="Basel">Basel</MenuItem>
                    <MenuItem value="Bern">Bern</MenuItem>
                    <MenuItem value="Luzern">Luzern</MenuItem>
                    <MenuItem value="Zug">Zug</MenuItem>
                    <MenuItem value="Zürich">Zürich</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    select
                    fullWidth
                    required
                    id="salutation"
                    name="salutation"
                    label="Anrede"
                    value={values.salutation}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        salutation: e.target.value
                      });
                    }}
                    error={touched.salutation && Boolean(errors.salutation)}
                    helperText={touched.salutation && errors.salutation}
                    onBlur={handleBlur}
                    native
                    inputProps={{
                      id: 'salutation-required'
                    }}
                  >
                    <MenuItem value="" />
                    <MenuItem value="Herr">Herr</MenuItem>
                    <MenuItem value="Frau">Frau</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    id="firstName"
                    name="firstName"
                    color="primary"
                    label="Vorname"
                    value={values.firstName}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      this.props.liftUpStateToMatchingProfile({
                        firstName: e.target.value
                      });
                    }}
                    fullWidth
                    required
                    minLength="2"
                    maxLength="100"
                    autoFocus
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    id="lastName"
                    name="lastName"
                    color="primary"
                    label="Nachname"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        lastName: e.target.value
                      });
                    }}
                    helperText={touched.lastName && errors.lastName}
                    error={touched.lastName && Boolean(errors.lastName)}
                    required
                    maxLength="100"
                    minLength="2"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <KeyboardDatePicker
                    id="dateOfBirth"
                    name="dateOfBirth"
                    label="Geburtsdatum"
                    format="dd.MM.yyyy"
                    cancelLabel="Abbrechen"
                    disableFuture
                    animateYearScrolling={false}
                    value={values.dateOfBirth || null}
                    onBlur={handleBlur}
                    error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                    onChange={(value) => {
                      setFieldValue('dateOfBirth', value);
                      // eslint-disable-next-line
                      if (value instanceof Date && !isNaN(value)) {
                        // move the time away from midnight, opposite direction of utc offset
                        const offset = -value.getTimezoneOffset();
                        value.setHours(Math.trunc(offset / 60), offset % 60);
                      }
                      this.props.liftUpStateToMatchingProfile({
                        dateOfBirth: value
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    onBlur={handleBlur}
                    value={values.emailAddress}
                    color="primary"
                    label="E-Mail"
                    helperText={touched.emailAddress && errors.emailAddress}
                    error={touched.emailAddress && Boolean(errors.emailAddress)}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        emailAddress: e.target.value
                      });
                    }}
                    required
                    type="email"
                    maxLength="100"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    id="street"
                    name="street"
                    onBlur={handleBlur}
                    value={values.street}
                    helperText={touched.street && errors.street}
                    error={touched.street && Boolean(errors.street)}
                    color="primary"
                    label="Straße"
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        street: e.target.value
                      });
                    }}
                    required
                    maxLength="100"
                    minLength="2"
                    fullWidth
                  />
                </div>
              </div>

              <div className="row pb-2">
                <div className="col-md-12 col-lg-3">
                  <TextField
                    id="postalCode"
                    name="postalCode"
                    onBlur={handleBlur}
                    value={values.postalCode}
                    color="primary"
                    label="PLZ"
                    error={touched.postalCode && Boolean(errors.postalCode)}
                    helperText={touched.postalCode && errors.postalCode}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        postalCode: e.target.value
                      });
                    }}
                    required
                    min="1000"
                    max="99999"
                    type="number"
                    fullWidth
                  />
                </div>
                <div className="col-md-12 col-lg-9">
                  <TextField
                    id="city"
                    name="city"
                    onBlur={handleBlur}
                    value={values.city}
                    color="primary"
                    label="Ort"
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        city: e.target.value
                      });
                    }}
                    required
                    maxLength="100"
                    minLength="2"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12">
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    color="primary"
                    label="Telefon"
                    onChange={(e) => {
                      handleChange(e);
                      this.props.liftUpStateToMatchingProfile({
                        phoneNumber: e.target.value
                      });
                    }}
                    required
                    type="tel"
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>
          <Typography className="text-center" color="error" paragraph variant="body1">
            {this.state.commonErrorMessage}
          </Typography>
          <div className="row">
            <div className="col-md-2 offset-md-8 text-center mb-3">
              <Button onClick={this.props.handleBack} variant="contained" color="primary" fullWidth>
                Zurück
              </Button>
            </div>
            <div className="col-md-2 pl-3 text-center pr-3">
              <Button variant="contained" type="submit" color="primary" fullWidth disabled={!isValid}>
                Speichern
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  render() {
    return (
      <div>
        {this.renderDropZone()}
        <Divider className="mt-3 mb-3" />
        {this.renderForm()}
        <RegistrationConsentPopUp
          key="registrationConsentPopUp"
          open={this.state.showRegistrationConsentPopUp}
          handleClose={this.handleCloseRegistrationConsentPopUp}
          handleConsentSubmit={this.finishAndHandleNext}
        />
      </div>
    );
  }
}

UploadCv.defaultProps = {
  uploadedDocuments: []
};

UploadCv.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  salutation: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  liftUpStateToMatchingProfile: PropTypes.func.isRequired,
  uploadedDocuments: PropTypes.array,
  street: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  xingURL: PropTypes.string.isRequired,
  linkedInURL: PropTypes.string.isRequired
};

export default UploadCv;
