import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

class ConfirmDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.props.titleText}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.descriptionText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              {this.props.buttonCancelText}
            </Button>
            <Button
              onClick={this.props.handleConfirm}
              color="primary"
              autoFocus
            >
              {this.props.buttonConfirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
ConfirmDialog.defaultProps = {
  buttonConfirmText: 'Bestätigen',
  buttonCancelText: 'Abbrechen',
  titleText: 'Aktion durchführen?',
  descriptionText: ''
};

ConfirmDialog.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  buttonConfirmText: PropTypes.string,
  buttonCancelText: PropTypes.string,
  titleText: PropTypes.string,
  descriptionText: PropTypes.string
};
export default ConfirmDialog;
