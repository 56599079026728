import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

// material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// const selectorLabels = {
//   main: { 1: '1 Kaufmännisch', 2: '2 Technisch', 3: '3 Dienstleistung' },
//   1: {
//     11: '11	Administration/HR/Consulting/CEO',
//     12: '12	Finanzen/Treuhand/Immobilien',
//     13: '13	Banking/Versicherungswesen',
//     14: '14	Einkauf/Logistik/Trading',
//     15: '15	Marketing/Kommunikation/PR',
//     16: '16	Verkauf/Innendienst/Kundendienst'
//   },
//   2: {
//     21: '21 Informatik/Telekommunikation',
//     22: '22 Chemie/Pharma/Bio',
//     23: '23 Elektronik/Technik/Uhren',
//     24: '24 Maschinen-/Anlagebau/Produktion',
//     25: '25 Bau/Architektur/Immobilien',
//     26: '26 Fahrzeuge/Lager/Transporte'
//   },
//   3: {
//     31: '31	Medizin/Pflege/Therapie',
//     32: '32	Verwaltung/Bildung/Soziales',
//     33: '33	Gastro/Hotellerie/Lebensmittel/Tourismus',
//     34: '34	Grafik/Typografie/Druck',
//     35: '35	Bewachung/Polizei/Zoll/Rettung',
//     36: '36	Kultur/Sport/Wellness/Körperpflege'
//   },

//   21: {
//     2101: '2101 Beratung/Wirtschaftsinformatik',
//     2102: '2102 Datenbanken',
//     2103: '2103 Netzwerktechnik/IT-Security',
//     2104: '2104 PC Support/Help Desk',
//     2105: '2105 IT-Management/-Projekte',
//     2106: '2106 ERP/Business Intelligence',
//     2107: '2107 Software Engineering/Programmierung',
//     2108: '2108 Systemtechnik/-administration',
//     2109: '2109 Webdesign/-programmierung'
//   }
// };

const selectorLabels = {
  main: { 1: '1 Kaufmännisch', 2: '2 Technisch', 3: '3 Dienstleistung' },
  1: {
    11: '11 Administration/HR/Consulting/CEO',
    12: '12 Finanzen/Treuhand/Immobilien',
    13: '13 Banking/Versicherungswesen',
    14: '14 Einkauf/Logistik/Trading',
    15: '15 Marketing/Kommunikation/PR',
    16: '16 Verkauf/Innendienst/Kundendienst'
  },
  2: {
    21: '21 Informatik/Telekommunikation',
    22: '22 Chemie/Pharma/Bio',
    23: '23 Elektronik/Technik/Uhren',
    24: '24 Maschinen-/Anlagebau/Produktion',
    25: '25 Bau/Architektur/Immobilien',
    26: '26 Fahrzeuge/Lager/Transporte'
  },
  3: {
    31: '31 Medizin/Pflege/Therapie',
    32: '32 Verwaltung/Bildung/Soziales',
    33: '33 Gastro/Hotellerie/Lebensmittel/Tourismus',
    34: '34 Grafik/Typografie/Druck',
    35: '35 Bewachung/Polizei/Zoll/Rettung',
    36: '36 Kultur/Sport/Wellness/Körperpflege'
  },
  11: {
    1101: ' 1101 CEO/Geschäftsleitung ',
    1102: ' 1102 Sekretariat/Empfang ',
    1103: ' 1103 Direktionsassistenz ',
    1104: ' 1104 Sachbearbeitung/Übersetzung ',
    1105: ' 1105 Personalwesen/-entwicklung ',
    1106: ' 1106 Beratung/Unternehmensentwicklung ',
    1107: ' 1107 Qualitätsmanagement ',
    1108: ' 1108 Juristen/Anwälte/Richter ',
    1109: ' 1109 Regulatory Affairs '
  },
  12: {
    1201: ' 1201 Audit/Revision/Wirtschaftsprüfung ',
    1202: ' 1202 Buchhaltung ',
    1203: ' 1203 Finanz-/Rechnungswesen ',
    1204: ' 1204 Controlling ',
    1205: ' 1205 Immobilien/Handel/Bewirtschaftung/Portfoliomanagement ',
    1206: ' 1206 Inkasso/Debitorenmanagement ',
    1207: ' 1207 Lohnbuchhaltung ',
    1208: ' 1208 Steuern ',
    1209: ' 1209 Treuhand '
  },
  13: {
    1301: ' 1301 Anlageberatung/Private Banking ',
    1302: ' 1302 Asset/Portfoliomanagement ',
    1303: ' 1303 Back-Office ',
    1304: ' 1304 Finanz-/Business Analyse ',
    1305: ' 1305 Fonds/Wertschriften/Handel ',
    1306: ' 1306 Kredit/Hypotheken/Firmenkunden ',
    1307: ' 1307 Kundenberatung ',
    1308: ' 1308 Risk Management/Compliance ',
    1309: ' 1309 Treasury/Controlling/Audit ',
    1310: ' 1310 Projektmanagement ',
    1311: ' 1311 Versicherung: Agentur/Broker ',
    1312: ' 1312 Versicherung: Vertrieb/Beratung ',
    1313: ' 1313 Versicherung: Aktuariat/Mathematik ',
    1314: ' 1314 Versicherung: Schaden/Leistungen ',
    1315: ' 1315 Underwriting/Produktmanagement '
  },
  14: {
    1401: ' 1401 Einkauf/Beschaffung ',
    1402: ' 1402 Logistik/Supply Chain Management ',
    1403: ' 1403 Import/Eport/Trading '
  },
  15: {
    1501: ' 1501 Produkt-/Brand-/Category-Management ',
    1502: ' 1502 Marketing ',
    1503: ' 1503 Online Marketing/Neue Medien ',
    1504: ' 1504 Event Management ',
    1505: ' 1505 Werbung ',
    1506: ' 1506 PR/Kommunikation ',
    1507: ' 1507 Medien/Verlagswesen '
  },
  16: {
    1601: ' 1601 Telefonverkauf ',
    1602: ' 1602 Verkauf/Beratung ',
    1603: ' 1603 Verkauf Aussendienst/Beratung/KAM ',
    1604: ' 1604 Verkauf Innendienst ',
    1605: ' 1605 Kundendienst/After Sales Service '
  },
  21: {
    2101: ' 2101 Beratung/Wirtschaftsinformatik ',
    2102: ' 2102 Datenbanken ',
    2103: ' 2103 Netzwerktechnik/IT-Security ',
    2104: ' 2104 PC Support/Help Desk ',
    2105: ' 2105 IT-Management/-Projekte ',
    2106: ' 2106 ERP/Business Intelligence ',
    2107: ' 2107 Software Engineering/Programmierung ',
    2108: ' 2108 Systemtechnik/-administration ',
    2109: ' 2109 Webdesign/-programmierung '
  },
  22: {
    2201: ' 2201 Apotheken/Drogerien ',
    2202: ' 2202 Biologie ',
    2203: ' 2203 Biotechnologie ',
    2204: ' 2204 Chemie F+E/Analytik/Produktion ',
    2205: ' 2205 Pharma F+E/Analytik/Produktion ',
    2206: ' 2206 Qualitätswesen '
  },
  23: {
    2301: ' 2301 Elektronik/Elektrotechnik ',
    2302: ' 2302 Mechanik/Uhren ',
    2303: ' 2303 Werkzeugbau ',
    2304: ' 2304 Medizinaltechnik ',
    2305: ' 2305 Energie-/Gebäudetechnik ',
    2306: ' 2306 Kundendienst ',
    2307: ' 2307 Servicetechnik ',
    2308: ' 2308 Qualitätswesen '
  },
  24: {
    2401: ' 2401 Verfahrenstechnik ',
    2402: ' 2402 Automation ',
    2403: ' 2403 Entwicklung/Engineering/Konstruktion ',
    2404: ' 2404 Produktion/Fertigung/Montage ',
    2405: ' 2405 Servicetechnik/Wartung/Unterhalt ',
    2406: ' 2406 Qualitätswesen '
  },
  25: {
    2501: ' 2501 Architektur/Planung ',
    2502: ' 2502 Innenarchitektur/Dekoration ',
    2503: ' 2503 Gartenbau/Landschaftsarchitektur ',
    2504: ' 2504 Bauleitung/Projektleitung ',
    2505: ' 2505 Bauführung/Projektleitung ',
    2506: ' 2506 Ingenieurwesen (Bau/Vermessung/Geotechnik/Raumplanung) ',
    2507: ' 2507 Bauspengler/Dachdecker ',
    2508: ' 2508 Metall-/Fassadenbau ',
    2509: ' 2509 Holzbau/Innenausbau ',
    2510: ' 2510 Boden-/Plattenleger ',
    2511: ' 2511 Maler/Gipser ',
    2512: ' 2512 Elektroplanung/-installation ',
    2513: ' 2513 HLKS Planung/Installation ',
    2514: ' 2514 Immobilienentwicklung ',
    2515: ' 2515 Facility Management '
  },
  26: {
    2601: ' 2601 Fahrzeugbau ',
    2602: ' 2602 Land-/Forstwirtschaft ',
    2603: ' 2603 Lager/Logistik/Disposition ',
    2604: ' 2604 Transportwesen/Disposition '
  },
  31: {
    3101: ' 3101 Labor/Analyse ',
    3102: ' 3102 Ärzte ',
    3103: ' 3103 Radiologie ',
    3104: ' 3104 Psychologie/Psychiatrie ',
    3105: ' 3105 Akutpflege ',
    3106: ' 3106 Langzeitpflege ',
    3107: ' 3107 Therapie/Orthopädie '
  },
  32: {
    3201: ' 3201 Öffentliche Verwaltung ',
    3202: ' 3202 Wissenschaft/Forschung ',
    3203: ' 3203 Lehrer/Dozenten ',
    3204: ' 3204 Sozial-/Heilpädagoge ',
    3205: ' 3205 Sozial-/Jugendarbeit ',
    3206: ' 3206 NGO/NPO/Verbandswesen '
  },
  33: {
    3301: ' 3301 Betriebsleitung/-administration ',
    3302: ' 3302 Küche ',
    3303: ' 3303 Service/Bar ',
    3304: ' 3304 Rezeption ',
    3305: ' 3305 Hauswirtschaft ',
    3306: ' 3306 Fleischfachmann/Bäcker/Konditor/Käser ',
    3307: ' 3307 Lebensmitteltechnologe/-ingenieur, F&E, Produktion, Qualitätsmanagement ',
    3308: ' 3308 Reiseberatung/-Leitung '
  },
  34: {
    3401: ' 3401 Drucker ',
    3402: ' 3402 Grafik/Design ',
    3403: ' 3403 Typografie/Polygrafie '
  },
  35: {
    3501: ' 3501 Bewachung/Kontrolle/Sicherheit ',
    3502: ' 3502 Polizei/Militär ',
    3503: ' 3503 Rettung/Sanität/Feuerwehr ',
    3504: ' 3504 Zoll/Grenzkontrolle '
  },
  36: {
    3601: ' 3601 Mode/Coiffeur/Kosmetik ',
    3602: ' 3602 Massage/Sport/Wellness ',
    3603: ' 3603 Kultur/Unterhaltung ',
    3604: ' 3604 Museum/Kunst ',
    3605: ' 3605 Film/Theater/Radio/Fernsehen '
  }
};

const Selector = ({ label, onClick, color }) => (
  <div className="row">
    <Button
      // variant="contained"
      // className="deleteIncomingCandidate-button"
      classes={{ text: 'text-transform-none' }}
      color={color}
      onClick={() => onClick()}
    >
      {label}
    </Button>
  </div>
);

Selector.defaultProps = {
  color: null
};

Selector.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string
};

class MatchingFormWorkFieldSelectionPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSelector: 'main'
    };
    console.log('Debug props: entry subSubEntry ', props.selectedWorkField);
  }

  handleSelectorClick(clickedSelector) {
    this.setState(prevState => {
      console.log('DEBUG activeSelector ', prevState.activeSelector);
      console.log('DEBUG clickedSelector ', clickedSelector);
      let newActiveSelector = clickedSelector;
      if (prevState.activeSelector === clickedSelector)
        newActiveSelector = 'main';
      return {
        activeSelector: newActiveSelector
      };
    });
  }

  handleClickWorkField(clickedWorkField) {
    this.props.handleClose(clickedWorkField);
  }

  removeNumbersFromName(workFieldName) {
    const trimmedWorkFieldName = workFieldName.trim();

    if (trimmedWorkFieldName.match(/^\d/)) {
      return trimmedWorkFieldName.substr(trimmedWorkFieldName.indexOf(' ') + 1);
    }
    return workFieldName;
  }

  renderCloseButton() {
    return (
      <div className="row">
        <Button
          // variant="contained"
          // className="deleteIncomingCandidate-button"
          style={{ wordWrap: 'all' }}
          color="primary"
          onClick={() =>
            this.props.removeCurrentWorkFieldFormWorkFieldSelectionPopUp(
              this.props.selectedWorkField
            )
          }
        >
          {this.props.selectedWorkField === 'none' ||
          !this.props.selectedWorkField
            ? 'Abbrechen'
            : 'Schließen und Tätigkeitsfeld entfernen'}
        </Button>
      </div>
    );
  }

  render() {
    const { activeSelector } = this.state;
    console.log('DEBUG render activeSelector', activeSelector);
    return (
      <Dialog
        // key={this.props.key}
        open={this.props.open}
        fullScreen={!isWidthUp('sm', this.props.width)}
      >
        <DialogTitle>Ausrichtung / Wunschtätigkeit</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Wählen Sie aus folgenden Kategorien:
          </DialogContentText>
          <div className="pl-2 pt-2">
            {Object.entries(selectorLabels.main).map(entry => {
              const fieldId = entry[0];
              const fieldLabel = entry[1];
              return (
                <React.Fragment key={`${fieldId} ${fieldLabel}`}>
                  <Selector
                    id={fieldId}
                    key={fieldId}
                    style={{ fontWeight: '700' }}
                    label={this.removeNumbersFromName(fieldLabel)}
                    onClick={() => this.handleSelectorClick(fieldId)}
                  />
                  {(fieldId === activeSelector ||
                    fieldId === activeSelector[0]) &&
                    selectorLabels &&
                    selectorLabels[fieldId] &&
                    Object.entries(selectorLabels[fieldId]).map(subEntry => {
                      const subFieldId = subEntry[0];
                      const subFieldLabel = subEntry[1];
                      return (
                        <div className="pl-2">
                          <Selector
                            id={subFieldId}
                            key={subFieldId}
                            label={this.removeNumbersFromName(subFieldLabel)}
                            onClick={() => this.handleSelectorClick(subFieldId)}
                          />
                          {subFieldId === activeSelector &&
                            Object.entries(selectorLabels[subFieldId]).map(
                              subSubEntry => {
                                const subSubFieldId = subSubEntry[0];
                                const subSubFieldLabel = subSubEntry[1];
                                return (
                                  <div className="pl-2">
                                    <Selector
                                      id={subSubFieldId}
                                      label={this.removeNumbersFromName(
                                        subSubFieldLabel
                                      )}
                                      color="primary"
                                      onClick={() =>
                                        this.handleClickWorkField({
                                          key: subSubFieldId,
                                          value: subSubFieldLabel
                                        })
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>
                      );
                    })}
                </React.Fragment>
              );
            })}
            <div className="pt-2">{this.renderCloseButton()}</div>
          </div>
        </DialogContent>
        {/* <DialogActions /> */}
      </Dialog>
    );
  }
}
MatchingFormWorkFieldSelectionPopUp.defaultProps = {
  open: false,
  selectedWorkField: null
};

MatchingFormWorkFieldSelectionPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  removeCurrentWorkFieldFormWorkFieldSelectionPopUp: PropTypes.func.isRequired,
  selectedWorkField: PropTypes.string,
  width: PropTypes.number.isRequired
};

export default withWidth()(MatchingFormWorkFieldSelectionPopUp);
