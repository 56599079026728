import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

// material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const Selector = ({ label, onClick, color }) => (
  <div className="row">
    <Button
      classes={{ text: 'text-transform-none' }}
      color={color}
      onClick={() => onClick()}
    >
      {label}
    </Button>
  </div>
);

Selector.defaultProps = {
  color: null
};

Selector.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string
};

class RegistrationConsentPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSelector: 'main'
    };
  }

  render() {
    const { activeSelector } = this.state;
    console.log('DEBUG render activeSelector', activeSelector);
    return (
      <Dialog
        open={this.props.open}
        fullScreen={!isWidthUp('sm', this.props.width)}
      >
        <DialogTitle>Einverständniserklärung</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Mit dem Hochladen Ihres CVs und/oder mit dem Link zu Ihren Social-Media-Profilen erklären Sie sich damit einverstanden, dass Ihre Daten in den Lienert-Bewerberpool aufgenommen werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={this.props.handleConsentSubmit} 
              color="primary"
              type="submit"
            >
              Speichern
            </Button>
          </DialogActions>
      </Dialog>
    );
  }
}
RegistrationConsentPopUp.defaultProps = {
  open: false,
};

RegistrationConsentPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleConsentSubmit: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};

export default withWidth()(RegistrationConsentPopUp);
