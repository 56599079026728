import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import 'url-search-params-polyfill';
import 'date-input-polyfill';

import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import de from 'date-fns/locale/de';
import * as CONSTANTS from './constants';
import MatchingProfileLogin from './matching/MatchingProfileLogin';
import MatchingProfileData from './matching/MatchingProfileData';
import ErrorPage from './general/ErrorPage';
// custom styling
import './App.css';

const systemPartner = CONSTANTS.brand;
const THEME = require('./theme')[systemPartner];

const theme = createMuiTheme(THEME.app);

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingIndicator: false
    };
  }

  liftUpStateToApp = (stateObject) => {
    this.setState(stateObject);
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <div className={`ctm-bkg-${systemPartner}`} id="container-body">
            <AppBar className="p-2" style={THEME.appBar} id="header">
              <div className="text-center">
                <a href={THEME.homepage}>
                  <img
                    src={THEME.logo}
                    alt={THEME.logoAlt}
                    className="headerLogo"
                    style={{
                      maxHeight: '70px',
                      padding: '10px'
                    }}
                  />
                </a>
              </div>
            </AppBar>
            <div id="body">
              <Router>
                <div>
                  {this.state.showLoadingIndicator && (
                    <div id="loading-indicator-overlay">
                      <div id="loading-indicator-container">
                        <CircularProgress className="mb-3" size={60} thickness={5} />
                      </div>
                    </div>
                  )}
                  <Route exact path="/" render={() => <Redirect to="/myprofile/login" />} />
                  <Route exact path="/err" render={() => <ErrorPage />} />
                  <Route exact path="/myprofile" render={() => <Redirect to="/myprofile/login" />} />
                  <Route exact path="/myprofile/login" render={() => <MatchingProfileLogin liftUpStateToApp={this.liftUpStateToApp} />} />
                  <Route exact path="/myprofile/data" render={() => <MatchingProfileData liftUpStateToApp={this.liftUpStateToApp} />} />
                </div>
              </Router>
            </div>
            <footer id="footer" className="page-footer font-small">
              <div className="footer-copyright text-center py-3">
                <a href={THEME.imprintLink} className="p-3" style={{ color: '#000' }}>
                  Impressum
                </a>
              </div>
            </footer>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
