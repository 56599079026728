const theme = {
  lienert: {
    homepage: 'https://www.joerg-lienert.ch/',
    logo: '/img/lienert_logo.svg',
    logoAlt: 'Startseite',
    appBar: {
      background: '#092455'
    },
    app: {
      typography: {
        useNextVariants: true,
        fontFamily: ['Optima nova LT Pro', 'Times New Roman'].join(','),
        h4: {
          fontWeight: 'bold'
        }
      },
      palette: {
        primary: {
          main: '#092455',
          light: '#092455',
          veryLight: '#092455',
          contrastText: '#fff'
        },
        secondary: { main: 'rgb(0,119,136)' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS
            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    baseColor: '#092455',
    imprintLink: 'https://www.joerg-lienert.ch/de/footer/menu/rechtliches.html'
  }
};

module.exports = theme;
