const text = {
  MatchingProfileLogin: {
    headline: 'My Lienert Profil – Anmeldung / Login',
    description:
      'Hier können Sie Ihr persönliches Suchprofil anlegen, ändern oder löschen. Sie erhalten anschliessend per E-Mail den Link, um verschiedene Kriterien zu Ihrer Wunschtätigkeit zu erfassen oder anzupassen.',
    formLabels: {
      salutation: 'Anrede',
      firstName: 'Vorname',
      lastName: 'Nachname',
      mail: 'E-Mail',
      values: {
        mister: 'Herr',
        miss: 'Frau'
      }
    },
    checkBoxDataPrivacyPre: 'Ich habe die ',
    checkBoxDataPrivacyMid: 'Datenschutzerklärung',
    checkBoxDataPrivacyAfter: ' der Jörg Lienert AG gelesen und akzeptiere diese.',
    submit: 'Senden',
    hint: 'Bitte den Bedingungen zustimmen.',
    renderSuccessMessage: {
      successMessage: 'Danke für Ihre Anmeldung',
      description: 'Sie erhalten in Kürze eine E-Mail mit dem Link zu Ihrem gültigen Suchprofil.',
      support: 'Sollten Sie innerhalb von 10 Minuten keine E-Mail erhalten, wenden Sie sich bitte an'
    }
  }
};
module.exports = text;
