import React, { PureComponent } from 'react';
import $ from 'jquery';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

// custom components
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TEXT from '../text';
import * as CONSTANTS from '../constants';

class MatchingProfileLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      salutation: '',
      submitted: false,
      checked: false
    };
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.email.toLowerCase(),
      salutation: this.state.salutation,
      brand: CONSTANTS.brand
    };
    this.props.liftUpStateToApp({ showLoadingIndicator: true });
    const url = CONSTANTS.createCandidateMatchingProfileURL;

    const tokenFromLocalStorage = window.localStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done((response) => {
        if (!response) window.location = '/err';
        this.setState({ submitted: true });
        this.props.liftUpStateToApp({ showLoadingIndicator: false });
      })
      .fail(() => {
        window.location = '/err';
      });
  };

  renderSuccessMessage() {
    return (
      <div>
        <Typography variant="h4" component="h4" color="secondary" className="pb-3">
          {TEXT.MatchingProfileLogin.renderSuccessMessage.successMessage}
        </Typography>{' '}
        <Typography paragraph>{TEXT.MatchingProfileLogin.renderSuccessMessage.successMessage}</Typography>
        <Typography paragraph>
          {TEXT.MatchingProfileLogin.renderSuccessMessage.support} <a href="mailto:luzern@joerg-lienert.ch">luzern@joerg-lienert.ch</a>
        </Typography>
      </div>
    );
  }

  renderLoginForm() {
    const salutationValues = {
      mister: TEXT.MatchingProfileLogin.formLabels.values.mister,
      miss: TEXT.MatchingProfileLogin.formLabels.values.miss
    };

    const handleCheckboxClick = () => {
      this.setState((prevState) => ({ checked: !prevState.checked }));
    };

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography variant="h4" component="h4" fontWeight="" color="secondary" className="pb-3">
          {TEXT.MatchingProfileLogin.headline}
        </Typography>
        <Typography paragraph style={{ color: 'rgb(9,36,85)' }}>
          {TEXT.MatchingProfileLogin.description}
        </Typography>
        <FormControl required fullWidth>
          <InputLabel htmlFor="salutation-required">{TEXT.MatchingProfileLogin.formLabels.salutation}</InputLabel>
          <Select
            value={this.state.salutation}
            onChange={(e) => {
              this.setState({ salutation: e.target.value });
            }}
            name="salutation"
            native
            inputProps={{
              id: 'salutation-required'
            }}
          >
            <option value="" />
            <option value={salutationValues.mister}>{salutationValues.mister}</option>
            <option value={salutationValues.miss}>{salutationValues.miss}</option>
          </Select>
        </FormControl>
        <TextField
          color="primary"
          label={TEXT.MatchingProfileLogin.formLabels.firstName}
          value={this.state.firstName}
          onChange={(e) => this.setState({ firstName: e.target.value })}
          fullWidth
          required
          minLength="2"
          maxLength="100"
          autoFocus
        />
        <TextField
          color="primary"
          label={TEXT.MatchingProfileLogin.formLabels.lastName}
          value={this.state.lastName}
          onChange={(e) => this.setState({ lastName: e.target.value })}
          required
          maxLength="100"
          minLength="2"
          fullWidth
        />
        <TextField
          color="primary"
          label={TEXT.MatchingProfileLogin.formLabels.mail}
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          required
          type="email"
          maxLength="100"
          fullWidth
        />
        <div className="d-flex flex-row pt-3">
          <Checkbox className="checkBox" checked={this.state.checked} onClick={handleCheckboxClick} />
          <Typography paragraph className="mb-0 align-self-center" style={{ color: 'rgb(9,36,85)' }}>
            {TEXT.MatchingProfileLogin.checkBoxDataPrivacyPre}
            <Link target="_blank" href="https://joerg-lienert.ch/datenschutz">
              {TEXT.MatchingProfileLogin.checkBoxDataPrivacyMid}
            </Link>
            {TEXT.MatchingProfileLogin.checkBoxDataPrivacyAfter}
          </Typography>
        </div>
        <div className="d-flex flex-row pt-3">
          <Button className="login-button" variant="contained" color="primary" type="submit" disabled={!this.state.checked}>
            {TEXT.MatchingProfileLogin.submit}
          </Button>
          {!this.state.checked && (
            <Typography paragraph className="ml-3 mb-0 align-self-center text-danger">
              {TEXT.MatchingProfileLogin.hint}
            </Typography>
          )}
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="col-lg-6 offset-lg-3 col-md-12">
        <Paper className="p-3 login-paper">{this.state.submitted ? this.renderSuccessMessage() : this.renderLoginForm()}</Paper>
      </div>
    );
  }
}
MatchingProfileLogin.defaultProps = {};

MatchingProfileLogin.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired
};
export default withRouter(MatchingProfileLogin);
