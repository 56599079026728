import React, { Component } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as CONSTANTS from '../constants';

import CheckBoxes from '../generic_components/CheckBoxes';
import RadioButtons from '../generic_components/RadioButtons';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';

import MatchingFormWorkFieldSelectionPopUp from './MatchingFormWorkFieldSelectionPopUp';

const hierarchyCheckBoxOptions = ['CEO/MGL', 'Führungsposition', 'Fachkader/Spezialist', 'Mitarbeiter', 'Nachwuchskraft'];

const regionCheckBoxOptions = [
  'Ganze Schweiz',
  'Zentralschweiz',
  'Zürich/SH',
  'Ostschweiz/GR/FL',
  'Mittelland',
  'Bern',
  'Basel',
  'Oberwallis',
  'Westschweiz',
  'Tessin'
];

const salaryCheckBoxOptions = [
  { key: 70000, label: ">= 70'000.--" },
  { key: 100000, label: ">= 100'000.--" },
  { key: 130000, label: ">= 130'000.--" },
  { key: 160000, label: ">= 160'000.--" },
  { key: 190000, label: ">= 190'000.--" },
  { key: 220000, label: ">= 220'000.--" },
  { key: 250000, label: ">= 250'000.--" },
  { key: 280000, label: ">= 280'000.--" },
  { key: 310000, label: ">= 310'000.--" }
];

/**
 * option only available in candidateView
 */
// const financialShareCheckBox = ['Interesse an finanzieller Beteiligung'];

const languageNameDropDownOptions = [
  'Deutsch',
  'Englisch',
  'Französisch',
  'Spanisch',
  'Italienisch',
  'Russisch',
  'Holländisch',
  'Afghanisch',
  'Afrikaans',
  'Ägyptisch',
  'Albanisch',
  'Alt Hebräisch',
  'Altgriechisch',
  'Amharisch',
  'Arabisch',
  'Armenisch',
  'Asarisch',
  'Aserbaidschanisch',
  'Assyrisch',
  'Äthiopisch',
  'Bamun',
  'Baskisch',
  'Bengalisch',
  'Birmanisch',
  'Bosnisch',
  'Brasilianisch',
  'Bulgarisch',
  'Cantonesisch',
  'Cebuano',
  'Chinesisch',
  'Dänisch',
  'Dari',
  'Elsässisch',
  'Esperanto',
  'Estnisch',
  'Farsi',
  'Finnisch',
  'Flämisch',
  'Gälisch',
  'Gebärdensprache',
  'Georgisch',
  'Griechisch',
  'Gujarati',
  'Haussa',
  'Hebräisch',
  'Hindu',
  'Indisch',
  'Indonesisch',
  'Irakisch',
  'Iranisch',
  'Irisch',
  'Isländisch',
  'Israelisch',
  'Iwrit',
  'Japanisch',
  'Kambodschanisch',
  'Kantonesisch',
  'Karatschaisch',
  'Kasachisch',
  'Katalanisch',
  'Khmer',
  'Kinyarwanda',
  'Kisuaheli',
  'Koreanisch',
  'Kroatisch',
  'Laotisch',
  'Latein',
  'Lettisch',
  'Litauisch',
  'Luxemburgisch',
  'Madegassisch',
  'Makedonisch',
  'Malayalam',
  'Malaysisch',
  'Mandarin',
  'Marokkanisch',
  'Mazedonisch',
  'Moldawisch',
  'Mongolisch',
  'Neu Hebräisch',
  'Neugriechisch',
  'Niederländisch',
  'Norwegisch',
  'Oromo',
  'Pakistanisch',
  'Paschtu',
  'Persisch',
  'Philippinisch',
  'Polnisch',
  'Portugiesisch',
  'Punjabi',
  'Putonhua',
  'Rumänisch',
  'Sanskrit',
  'Schwedisch',
  'Serbokroatisch',
  'Skandinavisch',
  'Slawisch',
  'Slowakisch',
  'Slowenisch',
  'Sorbisch',
  'Suaheli',
  'Swahili',
  'Syrisch',
  'Tadschikisch',
  'Tagalog',
  'Tamil',
  'Tartarisch',
  'Telugu',
  'Thai',
  'Tigrigna',
  'Tschechisch',
  'Tunesisch',
  'Türkisch',
  'Ukrainisch',
  'Ungarisch',
  'Urdu',
  'Urkainisch',
  'Usbekisch',
  'Vietnamesisch',
  'Weißrussisch',
  'Yoruba',
  'Zaghawa'
];

const languageSkillLevelDropDownOptions = [
  'Muttersprache / Dolmetscher (C2 - GERR)',
  'verhandlungssicher / fließend (C1 - GERR)',
  'sehr gute Kenntnisse (B2 - GERR)',
  'gute Kenntnisse (B1 - GERR)',
  'erweiterte Grundkenntnisse (A2 - GERR)',
  'Grundkenntnisse (A1 - GERR)',
  'Anfänger'
];

const sectorDropDownOptions = [
  'Alle',
  'Bank/Finanzinstitute Versicherungen',
  'Baugewerbe, Haupt-/Neben-/ Zulieferbranche',
  'Beratung',
  'Bildung',
  'Chemie/Pharma',
  'Dienstleistungen',
  'Energie/-produktion/-versorgung/-verteilung',
  'Gastgewerbe/Hotellerie',
  'Gesundheits-/Sozialwesen',
  'Gewerbe/Handwerk',
  'Handel',
  'Immobilien',
  'Industrie',
  'Informatik/Telekommunikation',
  'Konsum-/Luxusgüterindustrie',
  'Land-/Forstwirtschaft/Holz',
  'Medien/Druckerei/Verlage',
  'Medizinaltechnik',
  'Rechts-/Wirtschaftsberatung',
  'Tourismus/Reisen/Freizeit',
  'Transport/Logistik',
  'NPO/NGO/Verbände',
  'Öffentliche Verwaltung'
];

// const partTimeDropdownOptions = ['<50%', '50% - 79%', '80% - 100%', '100%'];

// helper functions
const removeEmptyStringsFromArray = (originalArr) => {
  let mappedArray = [];
  if (Array.isArray(originalArr)) mappedArray = originalArr.filter((entry) => !!entry);
  return mappedArray;
};

const removeEmptyLanguageEntriesFromArray = (originalArr) => {
  let mappedArray = [];
  if (Array.isArray(originalArr)) mappedArray = originalArr.filter((entry) => !!entry.languageName);
  return mappedArray;
};

// const languageDropDowns = ['Deutsch', 'Englisch', ]

// Tab content : AA Matching
class MatchingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        hierarchy: props.data.hierarchy ? [...props.data.hierarchy] : [''],
        region: props.data.region || [],
        salary: props.data.salary ? [props.data.salary] : '',
        // financialShare: [],
        languageSkills:
          props.data.languageSkills && props.data.languageSkills.length > 0
            ? [...props.data.languageSkills, { languageName: '', languageLevel: '' }]
            : [
                {
                  languageName: 'Deutsch',
                  languageLevel: 'Muttersprache / Dolmetscher (C2 - GERR)'
                },
                { languageName: '', languageLevel: '' }
              ],
        workFields: props.data.workFields || [],
        sector: props.data.sector && props.data.sector.length > 0 ? [...props.data.sector, ''] : ['Alle', ''],
        interestedInStake: props.data.interestedInStake || false,
        interestedInTopJobsharing: props.data.interestedInTopJobsharing || false,
        partTimeTill: props.data.partTimeTill || 100,
        partTimeFrom: props.data.partTimeFrom || 100
      },
      selectedWorkField: 'none',
      showMatchingFormWorkFieldSelectionPopUp: false,

      showErrorMessage: false
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));

    this.allFormFieldKeys = Object.keys(this.state.formFields);
  }

  handleCloseMatchingFormWorkFieldSelectionPopUp(clickedWorkField) {
    console.log('DEBUG clickedWorkField', clickedWorkField);
    this.setState((prevState) => {
      const prevWorkFields = prevState.formFields.workFields;
      let newWorkFields = prevWorkFields.slice();

      // duplicate check / prevents double click
      const isAlreadySelected = prevWorkFields.some((el) => {
        const foundDuplicate = el.key === clickedWorkField.key;
        return foundDuplicate;
      });
      console.log('debug isAlreadySelected', isAlreadySelected);

      // add in case no duplicate
      if (!isAlreadySelected && prevState.selectedWorkField !== 'none') {
        newWorkFields = newWorkFields.filter((workFields) => workFields.key !== prevState.selectedWorkField);
        newWorkFields.push(clickedWorkField);
      } else if (!isAlreadySelected) newWorkFields.push(clickedWorkField);

      return {
        formFields: {
          ...prevState.formFields,
          workFields: newWorkFields
        },
        showMatchingFormWorkFieldSelectionPopUp: false,
        selectedWorkField: 'none'
      };
    });
  }

  removeCurrentWorkFieldFormWorkFieldSelectionPopUp(workFieldKeyToBeRemoved) {
    console.log('DEBUG workFieldKeyToBeRemoved', workFieldKeyToBeRemoved);
    this.setState((prevState) => {
      const newWorkField = prevState.formFields.workFields.slice();
      const filterdWorkFields = newWorkField.filter((workFields) => workFields.key !== workFieldKeyToBeRemoved);
      return {
        formFields: {
          ...prevState.formFields,
          workFields: filterdWorkFields
        },
        showMatchingFormWorkFieldSelectionPopUp: false,
        selectedWorkField: 'none'
      };
    });
  }

  validateCheckBoxSelection(checkBoxFormId) {
    let isValid = false;
    if (checkBoxFormId === 'hierarchy') {
      // allow max 2 check box checked
      if (this.state.formFields.hierarchy.length < 3) isValid = true;
    } else isValid = true;
    return isValid;
  }

  handleRadioChange = (checkBoxFormId, checkBoxId) => {
    console.log('DEBUG handleCheckBoxChange', checkBoxFormId, checkBoxId);
    this.setState((prevState) => {
      const dropDownSelection = [checkBoxId];
      const updatedState = {
        ...prevState.formFields,
        [checkBoxFormId]: dropDownSelection
      };
      console.log('DEBUG updatedState', updatedState);
      return { formFields: updatedState };
    });
  };

  handleCheckBoxChange = (checkBoxFormId, checkBoxId) => {
    console.log('DEBUG handleCheckBoxChange', checkBoxFormId, checkBoxId);
    this.setState((prevState) => {
      let dropDownSelection = prevState.formFields[checkBoxFormId].slice();

      // special case for selection of all regions
      if (checkBoxFormId === 'region' && checkBoxId === 'Ganze Schweiz') {
        if (dropDownSelection.includes(checkBoxId)) dropDownSelection = [];
        else dropDownSelection = regionCheckBoxOptions;
      }
      // case: checkBox has been already checked
      else if (dropDownSelection.includes(checkBoxId)) {
        // remove checked option
        const index = dropDownSelection.indexOf(checkBoxId);
        dropDownSelection.splice(index, 1);
      }

      // add checked option in case validation is passed (e.g. limit of allowed selected checkboxes)
      else if (this.validateCheckBoxSelection(checkBoxFormId)) dropDownSelection.push(checkBoxId);
      const updatedState = {
        ...prevState.formFields,
        [checkBoxFormId]: dropDownSelection
      };
      console.log('DEBUG updatedState', updatedState);
      return { formFields: updatedState };
    });
  };

  handleDropDownChange = (e, index) => {
    const dropdownName = e.target.name;
    const newValue = e.target.value;
    this.setState((prevState) => {
      const originalLanguageState = prevState.formFields.languageSkills;

      // deep clone of original languageSkills state
      let newLanguageState = originalLanguageState
        // .slice()
        .map((l) => Object.assign({}, l));

      const languageState = newLanguageState[index];
      languageState[dropdownName] = newValue;

      if (dropdownName === 'languageName') {
        // remove empty languageName fields
        newLanguageState = removeEmptyLanguageEntriesFromArray(newLanguageState);

        // remove duplicates
        let duplicateFound = false;
        newLanguageState = newLanguageState.filter((item) =>
          // check if new value exist already in original languageState
          {
            const isDuplicate = originalLanguageState.some(
              (originalEl) => originalEl.languageName === newValue && item.languageName === newValue
            );
            const keepLanguage = duplicateFound || !isDuplicate;
            if (isDuplicate) duplicateFound = true;
            return keepLanguage;
          }
        );

        // add new empty row
        newLanguageState.push({
          languageName: '',
          languageLevel: ''
        });
      }

      // set updated state for languageSkills formFields
      return {
        formFields: {
          ...prevState.formFields,
          languageSkills: newLanguageState
        }
      };
    });
  };

  handleSectorDropDownChange = (e, index) => {
    const newValue = e.target.value;
    this.setState((prevState) => {
      const sectorState = prevState.formFields.sector;
      sectorState[index] = newValue;

      // remove all empty dropDowns
      const trimmedArr = removeEmptyStringsFromArray(sectorState);

      // remove duplicates
      const newSectorState = trimmedArr.filter((item, pos) => trimmedArr.indexOf(item) === pos);

      // add empty dropDown
      newSectorState.push('');
      return {
        formFields: { ...prevState.formFields, sector: newSectorState }
      };
    });
  };

  mapLanguageSkills(languageArr) {
    const mappedLanguageSkills = [];
    if (Array.isArray(languageArr))
      languageArr.forEach((languageSkills) => {
        if (languageSkills.languageName) mappedLanguageSkills.push(languageSkills);
      });
    return mappedLanguageSkills;
  }

  updateProjectMatchingAPICall(updatedData) {
    const requestBody = {
      guestToken: this.props.token,
      brand: 'lienert',
      updateData: {
        hierarchy: updatedData.hierarchy,
        region: updatedData.region,
        salary: updatedData.salary[0],
        languageSkills: this.mapLanguageSkills(updatedData.languageSkills),
        workFields: updatedData.workFields,
        sector: removeEmptyStringsFromArray(updatedData.sector),
        interestedInStake: this.state.formFields.interestedInStake,
        interestedInTopJobsharing: this.state.formFields.interestedInTopJobsharing,
        partTimeFrom: this.state.formFields.partTimeFrom,
        partTimeTill: this.state.formFields.partTimeTill
      }
    };
    this.props.liftUpStateToApp({ showLoadingIndicator: true });

    const url = CONSTANTS.updateGuestCandidateMatchingProfileURL;

    const tokenFromLocalStorage = window.localStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(() => {
        this.props.liftUpStateToApp({ showLoadingIndicator: false });
      })
      .fail(() => {
        window.location = '/err';
      });
  }

  getUpdateData(key) {
    // let result;

    // const initialValue = this.initialState.formFields[key]; // LATER
    // // special case for dropDowns with key value pairs
    // if (typeof initialValue === 'object')
    //   initialValue = this.props.initialData[key].id;

    let currentValue;
    // case inputField
    if (this.state.formFields[key]) currentValue = this.state.formFields[key];
    // case dropDowns
    else currentValue = this.state.formFields[key];

    // TODO impl. later with changeHistory
    // if (initialValue !== currentValue) {
    // handle case initialValue and currentValue both undefined, empty or null
    // if (initialValue || currentValue)
    const result = currentValue;
    // }
    return result;
  }

  generateUpdateData() {
    const updatedData = {};
    this.allFormFieldKeys.forEach((fieldKey) => {
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) {
        updatedData[fieldKey] = updatedValue;
      }
    });

    return updatedData;
  }

  updateData() {
    const updatedData = this.generateUpdateData();
    console.log('updatedData inside genericForm', updatedData);
    this.updateProjectMatchingAPICall(updatedData);
  }

  handleSubmitAndUploadButtonClick = (e) => {
    e.preventDefault();
    const formFields = this.state.formFields;

    if (
      formFields.hierarchy &&
      formFields.hierarchy.length > 0 &&
      formFields.region &&
      formFields.region.length > 0 &&
      (formFields.salary > 0 || formFields.salary.length > 0) &&
      formFields.languageSkills &&
      formFields.languageSkills.filter((val) => val.languageName !== '').length > 0 &&
      formFields.workFields &&
      formFields.workFields.filter((val) => val.key !== '').length > 0 &&
      formFields.sector &&
      formFields.sector.filter((val) => val !== '').length > 0
    ) {
      // Scroll up - hack
      document.getElementById('root').style.display = 'none';
      setTimeout(() => {
        document.getElementById('root').style.display = 'block';
      }, 200);

      this.updateData();
      this.props.handleSubmitAndUploadButtonClick();
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  renderLanguageDropDowns() {
    return (
      <div className="col-md-4 mb-3">
        <FormLabel htmlFor="Sprachkenntnisse">Sprachkenntnisse</FormLabel>
        {this.state.formFields.languageSkills.map((languageSkills, index) => (
          <div className="row" key={languageSkills.languageName}>
            <div className="col-6">
              <ValidatedDropDown
                id="languageName"
                // ref={this.formFieldRefs[index]}
                content={languageNameDropDownOptions}
                // label=""
                onChange={(e) => this.handleDropDownChange(e, index)}
                value={languageSkills.languageName}
                // {...this.state.inputFields.country}
              />
            </div>
            <div className="col-6">
              <ValidatedDropDown
                id="languageLevel"
                // ref={this.formFieldRefs[index]}
                content={languageSkillLevelDropDownOptions}
                // label=""
                onChange={(e) => this.handleDropDownChange(e, index)}
                value={languageSkills.languageLevel}
                // {...this.state.inputFields.country}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  removeNumbersFromName(workFieldName) {
    const trimmedWorkFieldName = workFieldName.trim();

    if (trimmedWorkFieldName.match(/^\d/)) {
      return trimmedWorkFieldName.substr(trimmedWorkFieldName.indexOf(' ') + 1);
    }
    return workFieldName;
  }

  renderWorkFieldSelection() {
    const workFields = this.state.formFields.workFields.slice();
    const placeHolderText = 'Tätigkeitsfeld hinzufügen';
    if (!workFields.includes(placeHolderText) && workFields.length <= 4) workFields.push(placeHolderText);
    return (
      <div className="col-md-4 mb-3">
        <FormLabel htmlFor="Ausrichtung / Wunschtätigkeit">Ausrichtung / Wunschtätigkeit</FormLabel>
        {workFields.map((workField) => {
          const workFieldName = typeof workField === 'string' ? workField : workField.value;

          return (
            <Button
              id="workFields"
              key={`workFields${workField && workField.value}`}
              fullWidth
              style={
                workFieldName !== placeHolderText
                  ? {
                      textTransform: 'none',
                      textAlign: 'left',
                      justifyContent: 'start',
                      label: { textAlign: 'left', justifyContent: 'start' }
                    }
                  : { text: 'text-transform-none' }
              }
              variant={workFieldName !== placeHolderText ? null : 'contained'}
              color={workFieldName !== placeHolderText ? null : 'primary'}
              onClick={() =>
                this.setState({
                  selectedWorkField: workField.key,
                  showMatchingFormWorkFieldSelectionPopUp: true
                })
              }
            >
              {this.removeNumbersFromName(workFieldName)}
            </Button>
          );
        })}
      </div>
    );
  }

  renderSectorDropDowns() {
    return (
      <div className="col-md-4 mb-3">
        <FormLabel htmlFor="Branchen">Branchen (unabhängig von Ausrichtung / Wunschtätigkeit)</FormLabel>
        {this.state.formFields.sector.map((sector, index) => (
          <ValidatedDropDown
            id="sector"
            key={sector}
            // ref={this.formFieldRefs[index]}
            content={sectorDropDownOptions}
            // label=""
            onChange={(e) => this.handleSectorDropDownChange(e, index)}
            value={sector}
            // {...this.state.inputFields.country}
          />
        ))}
      </div>
    );
  }

  renderSaveButton = () => (
    <div className="row">
      <div className="offset-md-4 col-md-4 pl-3 text-center mb-3" />
      <div className="col-md-4 pl-3 text-center">
        <Button
          onClick={this.handleSubmitAndUploadButtonClick}
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
        >
          speichern und weiter
        </Button>
      </div>
    </div>
  );

  render() {
    // console.log('debug state formFields', this.state.formFields);

    return (
      <div className="col-12">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-3">
              <svg
                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
              >
                <circle cx="12" cy="12" r="12" />
                <text className="MuiStepIcon-text" x="12" y="16" textAnchor="middle">
                  1
                </text>
              </svg>
              <p style={{ marginLeft: '10px', marginBottom: 0 }}>
                Bitte wählen Sie nachfolgend die Kriterien zu Ihrer Wunschtätigkeit aus. Durch speichern dieser Eingabe ist Ihr persönliches
                Suchprofil erfasst und Sie erhalten darauf abgestimmt per E-Mail passende Vakanzen.
              </p>
            </div>
            <div className="col-12 mb-2 pb-2 d-flex align-items-center">
              <svg
                style={{ float: 'left' }}
                className="MuiSvgIcon-root MuiStepIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                role="presentation"
              >
                <circle cx="12" cy="12" r="12" />
                <text className="MuiStepIcon-text" x="12" y="16" textAnchor="middle">
                  2
                </text>
              </svg>
              <p style={{ marginLeft: '10px', marginBottom: 0 }}>
                Vervollständigen Sie Ihre Angaben durch das Hochladen Ihres CVs und/oder die Verlinkung Ihres Social Media Profils. So sind
                Sie im Lienert-Bewerberpool registriert und können sich bei passenden Vakanzen mit wenigen Klicks bewerben.
              </p>
            </div>
          </div>
          <Divider className="mb-3" />
          <div className="row">
            <div className="col-12 mb-2 ">
              <CheckBoxes
                id="hierarchy"
                sectionLabel="Hierarchiestufe"
                options={hierarchyCheckBoxOptions}
                handleCheckBoxChange={this.handleCheckBoxChange}
                checked={this.state.formFields.hierarchy}
                layoutHorizontal
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <CheckBoxes
                id="region"
                sectionLabel="Arbeitsregionen"
                options={regionCheckBoxOptions}
                handleCheckBoxChange={this.handleCheckBoxChange}
                checked={this.state.formFields.region}
                layoutHorizontal
                // allSelector="Ganze Schweiz"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <RadioButtons
                id="salary"
                sectionLabel="Salär Bandbreite"
                options={salaryCheckBoxOptions}
                handleChange={this.handleRadioChange}
                selected={this.state.formFields.salary}
                layoutHorizontal
              />
            </div>
          </div>

          <div className="row">
            {this.renderLanguageDropDowns()}
            {this.renderWorkFieldSelection()}
            {this.renderSectorDropDowns()}
          </div>
          <div className="row">
            <div className="col-6 mt-3">
              <div className="row">
                <div className="col-12">
                  <FormLabel htmlFor="FinanzielleBeteiligung">Zusätzliche Optionen</FormLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FormControlLabel
                    className="checkBoxArea"
                    control={
                      <Checkbox
                        className="checkBox"
                        checked={this.state.formFields.interestedInStake}
                        onChange={() =>
                          this.setState((prevState) => ({
                            formFields: {
                              ...prevState.formFields,
                              interestedInStake: !prevState.formFields.interestedInStake
                            }
                          }))
                        }
                        // disabled
                      />
                    }
                    label="Interessiert an finanzieller Beteiligung"
                  />
                </div>
                <div className="col-12">
                  <FormControlLabel
                    className="checkBoxArea"
                    control={
                      <Checkbox
                        className="checkBox"
                        checked={this.state.formFields.interestedInTopJobsharing}
                        onChange={() =>
                          this.setState((prevState) => ({
                            formFields: {
                              ...prevState.formFields,
                              interestedInTopJobsharing: !prevState.formFields.interestedInTopJobsharing
                            }
                          }))
                        }
                        // disabled
                      />
                    }
                    label="Interessiert an Top-/Jobsharing"
                  />
                </div>
              </div>
            </div>
            <div className="col-6 mt-3 mb-3">
              <div className="row">
                <div className="col-12">
                  <FormLabel htmlFor="partTimeFrom">Pensum</FormLabel>
                </div>
                <div className="col-12 col-sm-6">
                  <TextField
                    id="partTimeFrom"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">von</InputAdornment>,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    value={this.state.formFields.partTimeFrom}
                    type="number"
                    defaultValue={100}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.setState((prevState) => ({
                        formFields: {
                          ...prevState.formFields,
                          partTimeFrom: value
                        }
                      }));
                    }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <TextField
                    id="partTimeTill"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">bis</InputAdornment>,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    value={this.state.formFields.partTimeTill}
                    type="number"
                    defaultValue={100}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.setState((prevState) => ({
                        formFields: {
                          ...prevState.formFields,
                          partTimeTill: value
                        }
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* PopUps */}
          <MatchingFormWorkFieldSelectionPopUp
            key={this.state.selectedWorkField || 'defaultKey'}
            open={this.state.showMatchingFormWorkFieldSelectionPopUp}
            selectedWorkField={this.state.selectedWorkField}
            handleClose={(clickedWorkField) => this.handleCloseMatchingFormWorkFieldSelectionPopUp(clickedWorkField)}
            removeCurrentWorkFieldFormWorkFieldSelectionPopUp={(clickedWorkField) =>
              this.removeCurrentWorkFieldFormWorkFieldSelectionPopUp(clickedWorkField)
            }
          />
        </div>
        <Divider className="mb-3" />
        {this.state.showErrorMessage && (
          <div className="text-center pb-3" style={{ color: 'red' }}>
            Das Suchprofil ist unvollständig
          </div>
        )}

        {this.renderSaveButton()}
      </div>
    );
  }
}
MatchingForm.defaultProps = {
  data: {}
};

MatchingForm.propTypes = {
  data: PropTypes.object,
  token: PropTypes.string.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSubmitAndUploadButtonClick: PropTypes.func.isRequired
};

export default MatchingForm;
