import React, { PureComponent } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import UploadCv from './UploadCV';
import ConfirmDialog from '../generic_components/ConfirmDialog';
// custom components
import MatchingForm from './MatchingForm';
// import CircularProgress from '@material-ui/core/CircularProgress';
import * as CONSTANTS from '../constants';

class MatchingProfileData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      matchingProfile: undefined,
      token: undefined,
      salutation: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      street: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      location: '',
      dateOfBirth: '',
      xingURL: '',
      linkedInURL: '',
      confirmDialogOpen: false,
      skipped: new Set(),
      uploadedDocuments: []
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    const parameter = params.get('tsg');
    this.setState({ token: parameter });
    if (parameter && parameter.length > 0)
      this.validateDataPrivacySettingsUniqueLink(parameter);
    else this.redirectToLogin();
  }

  liftUpStateToMatchingProfile = newState => {
    this.setState(newState);
  };

  redirectToLogin() {
    this.props.liftUpStateToApp({ showLoadingIndicator: false });

    this.props.history.push('/myprofile/login');
  }

  validateDataPrivacySettingsUniqueLink(guestToken) {
    const url = CONSTANTS.getGuestCandidateMatchingProfileURL;
    const requestBody = { guestToken, brand: 'lienert' };
    this.props.liftUpStateToApp({ showLoadingIndicator: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(responseBody => {
        if (!responseBody) this.redirectToLogin();
        this.props.liftUpStateToApp({ showLoadingIndicator: false });
        this.setState({
          matchingProfile: {
            hierarchy: responseBody.hierarchy,
            languageSkills: responseBody.languageSkills,
            region: responseBody.region,
            salary: responseBody.salary,
            sector: responseBody.sector,
            workFields: responseBody.workFields,
            interestedInStake: responseBody.interestedInStake,
            interestedInTopJobsharing: responseBody.interestedInTopJobsharing,
            partTimeFrom: responseBody.partTimeFrom,
            partTimeTill: responseBody.partTimeTill
          },
          firstName: responseBody.firstName,
          lastName: responseBody.lastName,
          street: responseBody.street,
          postalCode: responseBody.postalCode,
          city: responseBody.city,
          phoneNumber: responseBody.phoneNumber,
          location: responseBody.location,
          dateOfBirth: responseBody.dateOfBirth,
          xingURL: responseBody.xingURL,
          linkedInURL: responseBody.linkedInURL,
          salutation: responseBody.salutation,
          emailAddress: responseBody.emailAddress,
          uploadedDocuments: responseBody.uploadedDocuments
        });
      })
      .fail(() => {
        this.redirectToLogin();
      });
  }

  handleDeleteProfile = () => {
    const url = CONSTANTS.deleteGuestCandidateMatchingProfileURL;
    const requestBody = { guestToken: this.state.token, brand: 'lienert' };
    this.props.liftUpStateToApp({ showLoadingIndicator: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(() => {
        this.props.liftUpStateToApp({ showLoadingIndicator: false });
        this.redirectToLogin();
      })
      .fail(() => {
        this.redirectToLogin();
      });
  };

  getHeader(step) {
    if (step === 0) {
    return (
      <div className="row pl-3 pr-3">
        <div className="col-lg-6 col-md-12">
          <p>Anrede: {this.state.salutation}</p>
          <p>Vorname: {this.state.firstName}</p>
          <p>Nachname: {this.state.lastName}</p>
          <p>E-Mail: {this.state.emailAddress}</p>
        </div>
        <div className="col-lg-6 col-md-12">
          <Button
            color="primary"
            variant="contained"
            className="float-right mb-2"
            onClick={() => {
              this.setState({ confirmDialogOpen: true });
            }}
          >
            Profil löschen
          </Button>
        </div>
      </div>
    );
  }

    if (step === 1) {
      return (
        <div className="row pl-3 pr-3">
          <div className="col-lg-10 col-md-12">
            <div className="col-12 d-flex align-items-center mb-3">
                <svg className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active" 
                  focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"/>
                </svg>
                <p style={{marginLeft: '10px', marginBottom: 0}}>
                  Besten Dank. Ihr persönliches Suchprofil wurde erfolgreich gespeichert.
                </p>
            </div>
            <div className="col-12 mb-2 pb-2 d-flex align-items-center">
              <svg style={{float: 'left'}} className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                <circle cx="12" cy="12" r="12"/>
                <text className="MuiStepIcon-text" x="12" y="16" textAnchor="middle">2</text>
              </svg>
              <p style={{marginLeft: '10px', marginBottom: 0}}>
                Vervollständigen Sie Ihre Angaben durch das Hochladen Ihres CVs und/oder
                die Verlinkung Ihres Social Media Profils. So sind Sie im Lienert-Bewerberpool
                registriert und können sich zukünftig bei passenden Vakanzen mit wenigen
                Klicks bewerben.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-12">
           <a href="/myprofile/login">
            <Button
              color="primary"
              variant="contained"
              className="float-right mb-2"
            >
              Log Out
            </Button>
            </a>
          </div>
        </div>
      )
    }

    return <></>
  }

  getSteps() {
    return ['My Lienert Profil anlegen', 'CV hochladen und/oder Social Media Profil verlinken', 'Fertig'];
  }

  // now all steps are mandatory
  isStepOptional = () => false;

  handleSkipNext = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep + 1)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error(
        'Nicht optionale Schritte können nicht übersprungen werden'
      );
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep + 1);
      return {
        activeStep: state.activeStep + 2,
        skipped
      };
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep - 1,
      skipped
    });
  };

  getStepContent() {
    switch (this.state.activeStep) {
      case 0:
        return (
          this.state.matchingProfile && (
            <MatchingForm
              data={this.state.matchingProfile}
              token={this.state.token}
              liftUpStateToApp={this.props.liftUpStateToApp}
              handleSubmitAndUploadButtonClick={this.handleNext}
            />
          )
        );

      case 1:
        return (
          <div className="container">
            <UploadCv
              handleNext={this.handleNext}
              handleBack={this.handleBack}
              token={this.state.token}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              salutation={this.state.salutation}
              emailAddress={this.state.emailAddress}
              liftUpStateToMatchingProfile={this.liftUpStateToMatchingProfile}
              uploadedDocuments={this.state.uploadedDocuments}
              street={this.state.street}
              postalCode={this.state.postalCode}
              city={this.state.city}
              phoneNumber={this.state.phoneNumber}
              location={this.state.location}
              dateOfBirth={this.state.dateOfBirth}
              xingURL={this.state.xingURL}
              linkedInURL={this.state.linkedInURL}
            />
          </div>
        );
      case 2:
        return (
          <div className="container">
            <div className="row pl-2 pr-2">
              <div className="col-12">
                <p>
                  Besten Dank. Sie haben sich erfolgreich im Lienert-Bewerberpool registriert. 
                </p>
                <p>
                  Sollten Sie Ihre Daten ändern oder Ihr Profil löschen wollen, können Sie dies über den Link in Ihrer E-Mail tun oder hier jederzeit einen neuen
                  Anmeldelink anfordern:{' '}
                  <a href="/myprofile/login">Zurück zur Anmeldung</a>
                </p>
              </div>
            </div>
          </div>
        );

      default:
        return 'unknownStep';
    }
  }

  render() {
    const { activeStep } = this.state;
    const steps = this.getSteps();

    return (
      <div>
        <div className="container">
          {this.getHeader(activeStep)}
          <Divider className="mb-2" />

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              if (this.isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (this.isStepSkipped(index)) {
                props.completed = false;
              }
              return (
                <Step key={label} {...props}>
                  <StepLabel style={{maxWidth: '200px'}} {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Divider className="mb-3" />
        </div>

        <div className="container">
          <div className="row">{this.getStepContent()}</div>
        </div>
        <ConfirmDialog
          handleConfirm={this.handleDeleteProfile}
          handleClose={() => {
            this.setState({ confirmDialogOpen: false });
          }}
          open={this.state.confirmDialogOpen}
          titleText="Profil löschen?"
          descriptionText="Nach Ihrer Bestätigung erhalten Sie keine weiteren Benachrichtigungen mehr. Sämtliche von Ihnen zugestellten Unterlagen werden aus unserem System gelöscht."
        />
      </div>
    );
  }
}
MatchingProfileData.defaultProps = {};

MatchingProfileData.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(MatchingProfileData);
