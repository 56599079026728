import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const style = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  }
};

class CheckBoxes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true
      // errorText: ''
    };
  }

  onChange(checkBoxFormId, checkBoxId) {
    this.props.handleCheckBoxChange(checkBoxFormId, checkBoxId);
  }

  render() {
    return (
      <FormControl error={!this.state.isValid} fullWidth>
        {this.props.sectionLabel && (
          <FormLabel htmlFor={this.props.sectionLabel}>
            {this.props.required
              ? `${this.props.sectionLabel}*`
              : this.props.sectionLabel}
          </FormLabel>
        )}

        <div
          className="row"
          style={
            this.props.layoutHorizontal ? style.horizontal : style.vertical
          }
        >
          {this.props.options.map(option => {
            let label;
            let key;
            if (typeof option === 'string') {
              label = option;
              key = option;
            } else {
              label = option.label;
              key = option.key;
            }

            const checked = this.props.checked.includes(option);

            return (
              <FormGroup key={key}>
                <FormControlLabel
                  className="checkBoxArea"
                  style={{ minWidth: '200px', color: '#092455' }}
                  control={
                    <div className="pl-3">
                      <Checkbox
                        className="checkBox"
                        checked={checked}
                        onChange={() => this.onChange(this.props.id, key)}
                        // style={{ color: '#092455' }}

                        // disabled
                      />
                    </div>
                  }
                  label={label}
                />

                {!this.state.isValid && (
                  <FormHelperText>{this.state.errorText}</FormHelperText>
                )}
              </FormGroup>
            );
          })}
        </div>
      </FormControl>
    );
  }
}

CheckBoxes.defaultProps = {
  required: false,
  sectionLabel: '',
  layoutHorizontal: false
  // allSelector: null
  // disabled: false
};

CheckBoxes.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sectionLabel: PropTypes.string,
  // allSelector: PropTypes.string,
  options: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  layoutHorizontal: PropTypes.bool
  // disabled: PropTypes.bool
};

export default CheckBoxes;
